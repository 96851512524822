<script setup>
import {computed, ref} from "vue";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import DropdownLink from "@/Components/DropdownLink.vue";
import Dropdown from "@/Components/Dropdown.vue";


const props = defineProps({
    mobile: Boolean,
});

const changeLanguage = async (language) => {
    activeLanguage.value = language;
    axios.post(route('language.change', {locale: language}));
    localStorage.setItem('language', language);
    moment.locale(language);
    await loadLanguageAsync(language);
};

const activeLanguage = ref(getActiveLanguage());

const languageClass = computed(() => {
    if (activeLanguage.value === 'en') return 'fi-gb';
    return 'fi-' + activeLanguage.value;
});
</script>

<template>
    <!-- Language Dropdown -->
    <div class="relative ml-1">
        <Dropdown :align="mobile ? 'left' : 'right'" width="48">
            <template #trigger>
                        <span class="inline-flex rounded-md">
                          <button
                              class="flex gap-1 items-center rounded-md border border-transparent bg-transparent dark:text-gray-100 dark:bg-gray-600 px-3 py-2 text-sm font-medium leading-4 text-gray-500 transition duration-150 ease-in-out hover:text-gray-700 dark:hover:text-gray-300 focus:bg-gray-50 dark:focus:bg-gray-700 dark:focus:text-gray-200 focus:outline-none active:bg-gray-50 dark:active:bg-gray-700 dark:active:text-gray-200"
                              type="button">
                            <span :class="languageClass" class="fi"></span>
                            {{ activeLanguage }}
                          </button>
                        </span>
            </template>
            <template #content>
                <form method="POST" @submit.prevent="changeLanguage('de')">
                    <!-- German -->
                    <DropdownLink as="button">
                        <span class="fi fi-de"></span>
                        Deutsch
                    </DropdownLink>
                </form>
                <form method="POST" @submit.prevent="changeLanguage('en')">
                    <!-- English -->
                    <DropdownLink as="button">
                        <span class="fi fi-gb"></span>
                        English
                    </DropdownLink>
                </form>
            </template>
        </Dropdown>
    </div>
</template>
